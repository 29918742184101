import React from 'react'
import OurStoreBanner from "../components/sections/OurStoreBanner";
import StoreBannerImage from "../assets/images/StoreBanner.jpg"
import CardStore from "../components/sections/CardStore";

const OurStoreBannerData = {
  banner: StoreBannerImage,
  title:"Our Store",
}
const items = [
  { label: "Home", href: "/" },
  { label: "Our Projects", href: "/" },
];

const OurStore = () => {
  return (
    <>
      <OurStoreBanner data={OurStoreBannerData} subMenu={items}/>
      <CardStore/>
    </>
  )
}

export default OurStore;