import React from 'react'
import Button from "../form/Button";

const SingleOurPrice = ({ data }) => {
  const font = {
    fontFamily: "'Paytone One', sans-serif",
  }

  return (
    <>
      <div className="relative mx-auto">
        <div className="relative space-y-5 text-center mx-auto mb-8">
          <div className="px-8 py-2 text-center text-carnation-950 uppercase text-sm 2xl:text-base font-bold shadow-lg rounded-full inline-block">{data.subHeading}</div>
          <div className="text-4xl 2xl:text-5xl text-carnation-950" style={font}>{data.heading}</div>
        </div>
        <div className="relative">
          {data?.subData?.length > 0 &&
            <div className="relative w-full max-w-[500px] border border-gray-200 px-6 py-8 rounded-5xl">
              {data?.subData?.map((item) => (
                <div className="relative space-y-8">
                  <div className="relative flex flex-col w-full">
                    <div className="relative w-full flex flex-col mb-3">
                      <div className="relative flex items-center gap-1 mx-auto text-center mb-2">
                        <div className="text-base text-carnation-950"><i className={("fa-fw", item.icon)}></i></div>
                        <div className="text-xl 2xl:text-3xl font-medium text-carnation-950 uppercase leading-6">{item.version}</div>
                      </div>
                      <div className="relative text-center text-3xl 2xl:text-6xl text-carnation-950 mb-6" style={font}>{item.price}<span>{item.limit}</span></div>
                      <div className="text-slate-500 font-regular text-xs 2xl:text-base text-center leading-6">{item.description}</div>
                    </div>
                    <div className="relative w-full ">
                      <div className="after:absolute after:w-3/12 after:right-0 after:left-0 after:h-[1px] after:bg-slate-300  after:top-2  after:mx-auto"></div>
                    </div>
                  </div>
                  <ul className="list-disc ml-4 2xl:space-y-6">
                    <li className="text-slate-500 text-xs 2xl:text-base font-normal mb-3">{item.list1}</li>
                    <li className="text-slate-500 text-xs 2xl:text-base font-normal mb-3">{item.list2}</li>
                    <li className="text-slate-500 text-xs 2xl:text-base font-normal mb-3">{item.list3}</li>
                    <li className="text-slate-500 text-xs 2xl:text-base font-normal mb-3">{item.list4}</li>
                    <li className="text-slate-500 text-xs 2xl:text-base font-normal mb-3">{item.list5}</li>
                  </ul>
                  <div className="relative  mx-auto text-center !mt-8">
                    <Button
                      buttonClasses={'!px-6 !h-12 !text-carnation-950 !hover:bg-carnation-950 !hover:text-white !font-medium !text-white bg-carnation-950'}
                      buttonHasLink={true}
                      buttonLink={"#"}
                      buttonLabel={"Active Now"}
                      buttonLabelClasses={'text-sm'}
                      buttonEffect={"filled"}
                    />
                  </div>
                </div>
              ))}
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default SingleOurPrice;