import React from 'react';
import image from "../../assets/images/bannerShape.png";
import Image from "../elements/Image"; 
import Button from "../form/Button";

const MainBanner = ({ data }) => {
  const font = {
    fontFamily: "'Paytone One', sans-serif",
  };

  return (
    <>
      <div className="realtive"> 
        <div className="ralative w-full bg-gray-100 h-screen flex ">
          <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-6xl 3xl:max-w-8xl mx-auto flex items-center">
            <div className="relative flex items-center w-full pt-24 z-10">
              <div className="w-1/2">
                <div className="relative mr-4">
                  <div className="text-5xl 2xl:text-7xl text-gray-600 !leading-[1.2]" style={font}>
                    {data.bannerTiitle} <span className="text-carnation-950">
                      {data.bannerSubTiitle}</span>
                  </div>
                  <div className="text-base 2xl:text-xl  text-gray-600 mt-5 mr-24 leading-7 2xl:leading-8">
                    {data.description}
                  </div>
                  <div className="relative inline-block mt-8">
                    <Button
                      buttonClasses={"!md:px-5 !px-10 !h-14 md:!text-3xl text-lg  hover:bg-carnation-900 duration-300   "}
                      buttonHasLink={true}
                      buttonLabel={"Order"}
                      buttonLink={"/order"}
                    />
                  </div>
                </div>
              </div>
              <div className="w-1/2 relative flex">
                <div className="relative w-full mx-auto">
                  <div className="relative w-[550px] 2xl:w-[700px] h-[550px] 2xl:h-[700px] rounded-full overflow-hidden">
                    <Image
                      src={data.banner}
                      alt={"Logo"}
                      width={"100%"}
                      height={"100%"}
                      classes={"object-cover object-left"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> 
          <div className="absolute bottom-0 right-0 w-full h-screen overflow-hidden object-bottom">
            <Image
              src={image}
              alt={""}
              width={"100%"}
              height={"100%"}
              classes={
                "object-cover object-right"
              }
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default MainBanner;