import React from 'react'
import SingleOurPrice from "../items/SingleOurPrice";
const ourPriceData = {
  subHeading: "our price",
  heading: "See Our Pricing",
  subData: [
    {
      _id: "1",
      icon: "fa-solid fa-star",
      version: "Pro",
      price: "£1.90",
      limit: "/mth",
      description: "There are many variations of passages of Lorem Ipsum available, but the majority have",
      list1: "There are many variations of passages",
      list2: "Lorem Ipsum comes from sections 1.10.32 and 1.10.33",
      list3: "The standard chunk of Lorem Ipsum used since the ",
      list4: "1500s is reproduced below for those interested.",
      list5: "Contrary to popular belief, Lorem Ipsum is not simply random text.",
    }
  ]
}

const OurPrice = () => {
  return (
    <>
      <div className="relative  pt-32">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-6xl 3xl:max-w-8xl mx-auto flex items-center">
          <SingleOurPrice data={ourPriceData} />
        </div>
      </div>
    </>
  )
}

export default OurPrice;