import React from 'react'
import Image from "../elements/Image";

const WhyPurchase = ({ data }) => {
  const font = {
    fontFamily: "'Paytone One', sans-serif",
  }
  return (
    <>
      <div className="relative pt-32">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-6xl 3xl:max-w-8xl mx-auto flex space-x-10 bg-gray-100 rounded-7xl">
          <div className="relative flex items-start px-14 pt-16 ">
            <div className="relative w-full ">
              <div className="relative w-full max-w-[550px] h-full overflow-hidden">
                <Image
                  src={data.image}
                  alt={"why purchase"}
                  width={"100%"}
                  height={"100%"}
                  classes={"object-cover"}
                />
              </div>
            </div>
            <div className="relative space-y-5 w-full py-10 2xl:py-10">
              <div className="px-8 py-2 text-center text-carnation-950 uppercase text-sm 2xl:text-base font-bold shadow-lg rounded-full inline-block">{data.subHeading}</div>
              <div className="text-4xl 2xl:text-5xl text-carnation-950 !leading-[1.2]" style={font}>
                {data.heading}
              </div>
              <div className="relative text-sm 2xl:text-base text-carnation-700 !leading-8">
                {data.description}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WhyPurchase;