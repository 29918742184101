import React from 'react'
import Image from "../elements/Image";
import TrakerImage from "../../assets/images/tinyTraker.jpg"

const TinyTracker = () => {
  const font = {
    fontFamily: "'Paytone One', sans-serif",
  };
  return (
    <>
      <div className="relative pt-32">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-6xl 3xl:max-w-8xl mx-auto flex space-x-10">
          <div className="relative overflow-hidden rounded-2xl w-5/12">
            <Image
              src={TrakerImage}
              alt={"Logo"}
              width={"100%"}
              height={"100%"}
              classes={"object-cover object-left"}
            />
          </div>
          <div className="relative space-y-6 py-3 w-7/12">
            <div className="relative space-y-5 ">
              <div className="px-8 py-2 text-center text-carnation-950 uppercase text-sm 2xl:text-base font-bold shadow-lg rounded-full inline-block 2xl:mb-6">TINY TRACKER</div>
              <div className="text-4xl 2xl:text-5xl text-carnation-950 mr-20" style={font}>
                Purchase a Tiny Tracker
                Bracelet
              </div>
              <div className="relative text-sm 2xl:text-base text-carnation-700 !leading-7">
                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece
                of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock,
                a Latin professor at Hampden-Sydney.
              </div>
            </div>
            <div className="relative space-y-6">
              <div className="relative flex items-start space-x-4">
                <div className="flex items-center justify-center text-xl w-12 h-12 rounded-full shadow-lg bg-white shrink-0">
                  <i class="fa-regular fa-check"></i>
                </div>
                <div className="relative text-carnation-700 text-xs 2xl:text-base shrink !leading-7">
                  Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of
                  classical Latin literature from 45 BC, making it over 2000 years old
                </div>
              </div>
              <div className="relative flex items-start space-x-4">
                <div className="flex items-center justify-center text-xl w-12 h-12 rounded-full shadow-lg bg-white shrink-0">
                  <i class="fa-regular fa-check"></i>
                </div>
                <div className="relative text-carnation-700 text-xs 2xl:text-base shrink !leading-7">
                  Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of
                  classical Latin literature from 45 BC, making it over 2000 years old
                </div>
              </div>
              <div className="relative flex items-start space-x-4">
                <div className="flex items-center justify-center text-xl w-12 h-12 rounded-full shadow-lg bg-white shrink-0">
                  <i class="fa-regular fa-check"></i>
                </div>
                <div className="relative text-carnation-700 text-xs 2xl:text-base shrink !leading-7">
                  Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of
                  classical Latin literature from 45 BC, making it over 2000 years old
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TinyTracker;