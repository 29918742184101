import React from 'react';
import { Link } from "react-router-dom";
import Image from "../elements/Image";

const   SingleWhoWeAre = ({ item, isExpanded, toggleReadMore }) => {
  
  return (
    <>
      <div key={item.id} className="relative bg-[#D4E8FF] rounded-4xl px-6 py-10 w-full overflow-hidden hover:shadow-md duration-300 ease-in-out item-container">
        <div className="absolute -top-1/3 2xl:-top-1/2 left-0 right-0 bg-white w-full h-[300px] 2xl:h-[410px] rounded-full opacity-50"></div>
        <div className="relative space-y-6 flex flex-col h-full">
          <div className="relative w-40 h-40 2xl:w-52 2xl:h-52 rounded-full overflow-hidden img-wraper duration-300 ease-in-out 2xl:mb-3">
            <Image
              src={item.image}
              alt={"who we are image"}
              className="object-cover object-left w-full h-full img duration-300 ease-in-out"
            />
          </div>
          <div className="relative space-y-2 2xl:space-y-4">
            <div className="relative text-xl 2xl:text-2xl font-bold text-carnation-950">{item.title}</div>
            <div className="relative text-sm 2xl:text-base   text-black leading-6">
              {isExpanded ? item.description : `${item.description.slice(0, 100)}...`}
            </div>
          </div>
          <div className="text-sm font-medium text-carnation-950 hover:text-carnation-900 duration-300 !mt-auto">
            <div className="mt-6">
              <Link to="#" onClick={() => toggleReadMore(item.id)}>
                {isExpanded ? "Show Less" : "Read More"}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SingleWhoWeAre;