import React, { useState } from 'react'
import Image from "../elements/Image";
import { Link } from "react-router-dom";
import SingleWhoWeAre from "../items/SingleWhoWeAre";

const WhoWeAre = ({ data }) => {
  const [expanded, setExpanded] = useState({});

  const toggleReadMore = (id) => {
    setExpanded(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const font = {
    fontFamily: "'Paytone One', sans-serif",
  };
  return (
    <>
      <div className="relative pt-32">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-6xl 3xl:max-w-8xl mx-auto flex items-center">
          <div className="relative flex flex-col space-y-10 w-full">
            <div className="relative space-y-5 text-center mx-auto">
              <div className="px-8 py-2 text-center text-carnation-950 uppercase text-sm 2xl:text-base font-bold shadow-lg rounded-full inline-block">{data.subHeading}</div>
              <div className="text-4xl 2xl:text-5xl text-carnation-950" style={font}>{data.heading}</div>
            </div>
            <div className="relative"> 
              {data?.subdata?.length > 0 && (
                <div className="relative flex justify-between gap-4 sm:gap-10">
                  {data?.subdata?.map((item) => {
                    const isExpanded = expanded[item.id];
                    return (
                      <SingleWhoWeAre
                        key={item.id}
                        item={item}
                        isExpanded={isExpanded}
                        toggleReadMore={toggleReadMore}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WhoWeAre;