import React from 'react'
import Product1 from "../../assets/images/product1.png"
import Product2 from "../../assets/images/product2.png"
import Product3 from "../../assets/images/product3.png"
import Product4 from "../../assets/images/product4.png"
import SignleProduct from "../items/SignleProduct"
import Button from "../form/Button"

const ourProduct = {
  subHeading: "Our Store",
  heading: "Our Collection",
  subData: [
    {
      _id: "1",
      tag: "Bracelet",
      icon: "fa-regular fa-heart",
      icon2: "fa-solid fa-magnifying-glass",
      icon3: "fa-regular fa-chevron-right",
      productImage: Product1,
      productName: "Golden Elegance Cuff",
      productPrice: "£20.00",
      altProductPrice: "£24.00",
    },
    {
      _id: "2",
      tag: "Bracelet",
      icon: "fa-regular fa-heart",
      icon2: "fa-regular fa-magnifying-glass",
      icon3: "fa-regular fa-angle-right",
      productImage: Product2,
      productName: "Golden Elegance Cuff",
      productPrice: "£20.00",
      altProductPrice: "£24.00",
    },
    {
      _id: "3",
      tag: "Bracelet",
      icon: "fa-regular fa-heart",
      icon2: "fa-regular fa-magnifying-glass",
      icon3: "fa-regular fa-chevron-right",
      productImage: Product3,
      productName: "Golden Elegance Cuff",
      productPrice: "£20.00",
      altProductPrice: "£24.00",
    },
    {
      _id: "4",
      tag: "Bracelet",
      icon: "fa-regular fa-heart",
      icon2: "fa-regular fa-magnifying-glass",
      icon3: "fa-regular fa-chevron-right",
      productImage: Product4,
      productName: "Golden Elegance Cuff",
      productPrice: "£20.00",
      altProductPrice: "£24.00",
    },
  ]
}

const font = {
  fontFamily: "'Paytone One', sans-serif",
};

const OurStore = () => {
  return (
    <>
      <div className="relative pt-32">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-6xl 3xl:max-w-8xl mx-auto flex space-x-10">
          <div className="relative flex flex-col space-y-10 w-full">
            <div className="relative space-y-5 text-center mx-auto">
              <div className="px-8 py-2 text-center text-carnation-950 uppercase text-sm 2xl:text-base font-bold shadow-lg rounded-full inline-block">{ourProduct.subHeading}</div>
              <div className="text-4xl 2xl:text-5xl text-carnation-950" style={font}>{ourProduct.heading}</div>
            </div>
            <div className="relative ">
              <SignleProduct data={ourProduct} />
            </div>

            <div className="relative !inline-block mx-auto text-center !mt-8">
              <Button
                buttonClasses={'!px-6 !h-10 2xl:!h-14 2xl:!px-10 2xl:!text-2xl   !hover:bg-carnation-950 !hover:text-white !font-medium '}
                buttonHasLink={true}
                buttonLink={"#"}
                buttonLabel={"View More"}
                buttonLabelClasses={'text-sm'}
                buttonEffect={"filled"}
              />
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default OurStore;