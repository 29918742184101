import React, { useState } from 'react';
import Image from "../components/elements/Image";
import InputGroup from "../components/form/Input";
import footerImg from "../assets/images/footer_img.png"
import { Link } from "react-router-dom";

const DefaultFooter = ({ data }) => {
  console.log("hdhdh", data.columnThree)
  const font = {
    fontFamily: "'Paytone One', sans-serif",
  }
  const [formData] = useState({});

  return (
    <>
      <footer className="relative bg-carnation-950 pt-20">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-6xl 3xl:max-w-8xl mx-auto flex space-x-10">
          <div className="flex flex-col lg:flex-row gap-10 lg:gap-5 w-full">
            <div className="w-full xl:w-1/3 border-r border-carnation-900 ">
              <div className="text-xs 2xl:text-4xl text-white text-center lg:text-left" style={font}>{data.contactTitle}</div>
              {data.columnOne?.length > 0 &&
                <div className="mt-8 flex flex-col justify-center lg:justify-start gap-2 space-y-4">
                  {data.columnOne?.map((item) => (
                    <>
                      <div className="flex items-center space-x-3">
                        <div className="bg-white w-12 h-12 rounded-full flex items-center justify-center text-carnation-950 shrink-0">
                          <i className={("fa-brands fa-fw", item.icon)}></i>
                        </div>
                        <div className="text-xl font-medium  text-white shrink">{item.number}</div>
                      </div>
                    </>
                  ))}
                </div>
              }
            </div>
            <div className="w-full xl:w-1/3 border-r border-carnation-900  flex">
              <div className="relative mx-auto flex flex-col items-center">
                <div className="relative w-36 h-14 overflow-hidden">
                  <Image
                    src={data.columnTwo?.logo}
                    width={"100%"}
                    height={"100%"}
                    className="object-cover"
                  />
                </div>
                <div className="relative">
                  {data.columnTwo?.menu.length > 0 &&
                    <div className="mt-8 flex flex-col xl:flex-row justify-center lg:justify-start space-x-7 ">
                      {data.columnTwo?.menu?.map((item) => (
                        <>
                          <div className="flex items-center space-x-3">
                            <Link className="text-xl font-medium  text-white shrink">{item.name}</Link>
                          </div>
                        </>
                      ))}
                    </div>
                  }
                </div>
                <div className="relative">
                  {data.columnTwo?.socialMenu.length > 0 &&
                    <div className="mt-8 flex flex-col xl:flex-row justify-center  space-x-7 ">
                      {data.columnTwo?.socialMenu?.map((item) => (
                        <>
                          <div className="flex items-center space-x-1">
                            <Link className="bg-[#343862] w-12 h-12 rounded-full flex items-center justify-center text-white text-xl shrink-0">
                              <i className={("fa-brands fa-fw", item.icon)}></i>
                            </Link>
                          </div>
                        </>
                      ))}
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="w-full xl:w-1/3 flex">
              <div className="relative flex flex-col w-full justify-start space-y-6">
                <div className="relative">
                  <div className="text-xs 2xl:text-4xl text-white text-center lg:text-left" style={font}>{data.columnThree.subscribeTitle}</div> 
                </div>
                <div className="relative w-full space-y-3">
                  <div className="text-xs 2xl:text-sm text-white text-center lg:text-left" >{data.columnThree.subHeading}</div>
                  <div className="relative w-full">
                    <InputGroup
                      inputName={"name"}
                      inputPlaceholder={""}
                      inputClasses={"!bg-prontopsy-dodgerBlue !w-full !border !bg-[#2A2E5A] border-none rounded-xl !h-14 placeholder:!text-slate-100 text-white bg-white"}
                      inputValue={formData.email}
                    />
                    <div className="absolute  top-1/2 right-6 -translate-y-1/2 text-white">
                      <i className={("fa-brands fa-fw", data.columnThree.icon)}></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="relative w-full  h-[200px] overflow-hidden">
            <Image
              src={footerImg}
              alt={"Footer image"}
              width={"100%"}
              height={"100%"}
              classes="object-cover object-bottom "
            />
          </div>

        </div>
        <div className="absolute bottom-0 left-0 right-0  py-3">
          <div className="text-[12px] text-white text-center">Copyright © MyTinyTracker 2024 | All Rights & Reseved</div>
        </div>
      </footer>
    </>
  )
}

export default DefaultFooter