import React, { useState, useEffect } from 'react';
import { Link, NavLink } from "react-router-dom";
import { classNames } from "../helpers/classNames";
import Image from "../components/elements/Image";
import Button from "../components/form/Button";

const DefaultHeader = ({ data }) => {
  const [menuToggle, setMenuToggle] = useState(false);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
      <header className={`fixed z-30 top-0 w-full flex items-center py-6 transition-colors duration-300 ${isScrolled ? 'bg-white' : 'bg-transparent'
        }`}>
        <div className="w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-6xl 3xl:max-w-8xl mx-auto flex items-center">
          <div className="max-w-[160px] h-12 mr-auto">
            <Link to={"/"} className="block w-full h-full">
              <Image
                src={data.logo}
                alt={"Logo"}
                width={"100%"}
                height={"100%"}
                effect={"blur"}
                classes={"object-contain object-left"}
              />
            </Link>
          </div>
          <div className="relative flex gap-6 items-center">
            <nav className="relative">
              <ul className="relative1 flex text-base text-gray font-medium gap-3">
                {data.menu.map(item => (
                  <li key={item.id} className="relative">
                    <NavLink
                      to={item.link}
                      className={({ isActive }) =>
                        "px-3 block font-semibold hover:text-carnation-950 transition-all duration-200 " +
                        (isActive ? "text-slate-600" : "text-slate-800")
                      }
                    >
                      {item.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </nav>
            <div className="relative flex items-center gap-8">
              <div className="flex items-center gap-8">
                <div className="relative">
                  <Link to="/">
                    <div className="relative text-2xl">
                      <i class="fa-light fa-bag-shopping"></i>
                    </div>
                  </Link>
                  <div className="absolute -top-1 -right-2 text-[8px] text-white w-5 h-5 rounded-full bg-carnation-950 flex items-center justify-center">99+</div>
                </div>
                <div className="relative">
                  <Link to="/">
                    <div className="relative text-2xl">
                      <i class="fa-light fa-user"></i>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="relative">
                <Button
                  buttonClasses={"!md:px-5 px-1 !h-10 md:!text-3xl text-lg"}
                  buttonHasLink={true}
                  buttonLabel={"Order"}
                  buttonLink={"/order"}
                />
              </div>
            </div>
          </div>

          <div className="ml-auto xl:hidden">

          </div>
        </div>
      </header>
    </>
  )
}

export default DefaultHeader