import React, { useState } from 'react';
import Image from "../elements/Image";
import Button from "../form/Button";
import { Link } from "react-router-dom";
import MyModal from "../common/Modal";

const SignleProduct = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="relative flex flex-col">
        {data?.subData?.length > 0 &&
          <div className="relative w-full grid grid-cols-4 gap-6 space-y-3">
            {data?.subData?.map((item, index) => (
              <Link to={"/store"} className="relative" key={index}>
                <div className="relative border border-slate-200 rounded-5xl flex flex-col w-full product-item">
                  <div className="relative flex bg-white px-3 py-5 rounded-5xl">
                    <div className=" text-black bg-white px-4 flex items-center shadow-lg rounded-full h-8 text-sm">{item.tag}</div>
                    <div className="relative flax flex-col space-y-1 rounded-full px-1 py-1 ml-auto mb-2 shadow-lg">
                      <div className="relative bg-gray-200 text-sm flex items-center justify-center text-black w-8 h-8 rounded-full">
                        <i className={("fa-fw", item.icon)}></i>
                      </div>
                      <div className="relative bg-gray-200 text-sm flex items-center justify-center text-black w-8 h-8 rounded-full" onClick={handleModalOpen}>
                        <i className={("fa-fw", item.icon2)}></i>
                      </div> 
                    </div>
                  </div>
                  <div className="relative space-y-5 flex flex-col rounded-b-5xl product-img">
                    <div className="relative mx-auto flex flex-col">
                      <div className="relative w-44 2xl:w-56 overflow-hidden">
                        <Image
                          src={item.productImage}
                          alt={"who we are image"}
                          className="object-contain object-left w-full h-full img duration-300 ease-in-out"
                        />
                      </div>
                      <div className="relative !inline-block mx-auto text-center product-btn">
                        <Button
                          buttonClasses={'!px-6 !py-0 !h-10 !text-white !text-xs'}
                          buttonHasLink={true}
                          buttonLink={"#"}
                          buttonLabel={"Add To Cart"}
                          buttonLabelClasses={'text-xs'}
                          buttonEffect={"filled"}
                        />
                      </div>
                    </div>
                    <div className="relative mt-auto flex items-center w-full bg-slate-100 rounded-b-5xl py-4 px-5">
                      <div className="relative">
                        <div className="text-sm font-medium text-carnation-950 line-clamp-2 truncate text-ellipsis w-4/5">{item.productName}</div>
                        <div className="flex relative space-x-3 items-center">
                          <div className="text-base font-medium text-carnation-950">{item.productPrice}</div>
                          <div className="text-base text-carnation-200 line-through">{item.altProductPrice}</div>
                        </div>
                      </div>
                      <div className="relative ml-auto">
                        <div className="relative w-9 h-9 bg-gray-200 rounded-full flex items-center justify-center">
                          <i className={("fa-fw", item.icon3)}></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        }
      </div>

      <MyModal isOpen={isModalOpen} onClose={handleModalClose} />
    </>
  );
};

export default SignleProduct;
