import React from 'react';
import { Link, Outlet } from "react-router-dom";
import DefaultHeader from "../shared/DefaultHeader";
import DefaultFooter from "../shared/DefaultFooter";
import Logo from "../assets/images/logo.png"
import LogoAlt from "../assets/images/logoAlt.png"


const menuData = {
  logo: Logo,
  menu: [
    {
      _id: 1,
      name: "HOME",
      link: "/",
      isParent: false,
    },
    {
      _id: 2,
      name: "STORE",
      link: "/store",
      isParent: false,
    },
    {
      _id: 3,
      name: "FAQ",
      link: "/faq",
      isParent: false,
    },
  ]
};

const footerData = {
  contactTitle: "Contact Us",
  columnOne: [
    {
      _id: "1",
      icon: "fa-solid fa-phone",
      number: "01706 224121",
    },
    {
      _id: "2",
      icon: "fa-solid fa-location-dot",
      number: "Ballifeary Road,Ballochford,",
    },
    {
      _id: "3",
      icon: "fa-solid fa-envelope",
      number: "rigaw32291@eixdeal.com",
    }
  ],
  columnTwo: {
    logo: LogoAlt,
    menu: [
      {
        _id: "1",
        name: "HOME",
        link: "/",
      },
      {
        _id: "2",
        name: "STORE",
        link: "/ourstore",
      },
      {
        _id: "3",
        name: "FAQ",
        link: "/",
      }
    ],
    socialMenu: [
      {
        _id: 1,
        name: "Facebook",
        link: "https://www.facebook.com/",
        icon: "fa-brands fa-facebook-f",
      },
      {
        _id: 2,
        name: "Instagram",
        link: "https://www.instagram.com/",
        icon: "fa-brands fa-instagram", 
      },
      {
        _id: 3,
        name: "Twitter",
        link: "https://twitter.com/",
        icon: "fa-brands fa-x-twitter", 
      },
    ],
  },
  columnThree: {
    subscribeTitle: "Subscribe",
    subHeading:"Subscribe to Our Newsletter",
    icon:"fa-solid fa-paper-plane"
  }
}



const DefaultLayout = () => {
  return (
    <>
      <DefaultHeader data={menuData} />
      <Outlet />
      <DefaultFooter data={footerData}/>
    </>
  )
}

export default DefaultLayout;