import React from 'react';
import Quotes from '../../assets/images/quotes.png';
import Image from "../elements/Image";

const SingleTestimonial = ({ data }) => {
  const font = {
    fontFamily: "'Rozha One', serif",
  }
  return (
    <>
      <div className="relative mt-6">
        <div className="relative bg-gray-100 px-8 pt-12 pb-10 rounded-4xl z-0">
          <div className="relative space-y-4 flex w-full flex-col">
            <div className="text-xs 2xl:text-base text-center text-carnation-600 !leading-7">{data.description}</div>
            <div className="relative text-center inline-block mx-auto">
              <div className="text-xs xl:text-base font-medium text-carnation-950">{data.name}</div>
              <div className="after:absolute after:content-[''] after:w-4 after:h-0.5 after:bg-carnation-950 after:-inset-6 after:top-1/2"></div>
            </div>
          </div>
        </div>
        <div className="absolute -top-[20px] right-8 z-10">
          <div className="w-14 h-10 overflow-hidden">
            <Image
              src={Quotes}
              alt={"Quotes"}
              width={"100%"}
              height={"100%"}
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default SingleTestimonial;