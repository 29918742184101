import React from 'react';
import { Route, Routes, BrowserRouter} from "react-router-dom";
import DefaultLayout from "./layouts/DefaultLayout";
import Home from "./page/Home";
import OurStore from "./page/OurStore";

const CreateRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DefaultLayout />}>
          <Route path="/" exact element={<Home />}/>
        </Route>
        <Route path="/store" element={<DefaultLayout />}>
          <Route path="/store" exact element={<OurStore />}/>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default CreateRoutes;