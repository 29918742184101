import React, { useState } from "react";
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Image from "../elements/Image";
import product1 from "../../assets/images/product1.png"
import Button from "../form/Button";
import InputGroup from "../../components/form/Input"

const MyModal = ({ isOpen, onClose }) => {
  const font = {
    fontFamily: "'Paytone One',sans-serif"
  }
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const [value, setValue] = useState('1');

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleIncrement = () => {
    console.log("hi")
    setValue(prevValue => parseInt(prevValue) + 1);
  };

  const handleDecrement = () => {
    setValue(prevValue => (prevValue > 1 ? parseInt(prevValue) - 1 : 1));
  };


  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-6xl transform overflow-hidden rounded-2xl bg-white px-6 py-8 text-left align-middle shadow-xl transition-all">
                <div className="relative flex  w-full space-x-8">
                  <div className="relative w-1/2 rounded-5xl border border-slate-200 px-4 py-4 pb-16">
                    <div className=" flex flex-col w-full">
                      <div className="relative ml-auto mb-3">
                        <input
                          type="checkbox"
                          id="heart-checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          className="hidden"
                        />
                        <label
                          htmlFor="heart-checkbox"
                          className={`relative flex items-center justify-center w-14 h-14 cursor-pointer bg-slate-100 rounded-full
                        ${isChecked ? 'text-red-500' : 'text-gray-300'}`}
                        >
                          {isChecked ? (
                            <svg
                              className="w-6 h-6"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                                clipRule="evenodd"
                              />
                            </svg>
                          ) : (
                            <svg
                              className="w-6 h-6"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"></path>
                            </svg>
                          )}
                        </label>
                      </div>
                      <div className="relative w-[322px] h-[322px] overflow-hidden mx-auto">
                        <Image
                          src={product1}
                          width={"100%"}
                          height={"100%"}
                          classes="w-full h-full object-contain"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-1/2 space-y-4">
                    <div className="relative space-y-3">
                      <div className="relative text-2xl " style={font}>Rayssa Women’s Top</div>
                      <div className="text-base text-gray-600"> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum.</div>
                    </div>
                    <div className="relative flex items-center space-x-2 text-base">
                      <div className="text-gray-600">Available :</div>
                      <div className="text-green-500">In Stock</div>
                    </div>
                    <div className="relative flex items-center space-x-2 text-base">
                      <div className="text-gray-600">Quantity :</div>
                      <div className="relative flex items-center border border-slate-200 rounded-md overflow-hidden">
                        <div className="relative">
                          <Button
                            buttonClasses={"!md:px-6 px-1 !h-9 md:!text-3xl text-lg !rounded-none bg-transparent border-r border-slate-300 !text-slate-900"}
                            buttonLabel={"-"}
                            buttonFunction={handleDecrement}
                          />
                        </div>
                        <div>
                          {/* <InputGroup 
                           inputName={"name"}
                           inputType={"number"} // Use "number" as the string type
                           inputClasses={"!bg-prontopsy-dodgerBlue !text-center !w-12 !h-9 border !bg-white !border-none placeholder:!text-slate-500 text-carnation-950 bg-white !px-2 shadow-md !rounded-none"}
                           inputValue={value}
                           onChange={handleChange} // Pass the change handler
                          /> */}
                          <InputGroup
                            inputName={"name"}
                            inputType={"number"}
                            inputClasses={"!bg-prontopsy-dodgerBlue !text-center !w-12 !h-9 border !bg-white !border-none placeholder:!text-slate-500 text-carnation-950 bg-white !px-2 shadow-md !rounded-none"}
                            inputValue={value}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="relative">
                          <Button
                            buttonClasses={"!md:px-6 px-1 !h-9 md:!text-3xl text-lg !rounded-none bg-transparent border-l border-slate-300 !text-slate-900"}
                            buttonLabel={"+"}
                            buttonFunction={handleIncrement}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="relative">
                      <div className="text-gray-600">Size</div>
                      <div className="relative">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default MyModal;
