import React, { useState } from 'react';
import Product1 from "../../assets/images/product1.png";
import Product2 from "../../assets/images/product2.png";
import Product3 from "../../assets/images/product3.png";
import Product4 from "../../assets/images/product4.png";
import Product5 from "../../assets/images/product5.png";
import Product6 from "../../assets/images/product6.png";
import Product7 from "../../assets/images/product7.png";
import Product8 from "../../assets/images/product8.png";
import Product9 from "../../assets/images/product9.png";
import Product10 from "../../assets/images/product10.png";
import Product11 from "../../assets/images/product11.png";
import Product12 from "../../assets/images/product12.png";
import SignleProduct from "../items/SignleProduct";
import InputGroup from "../../components/form/Input";
import { Listbox } from '@headlessui/react';

const ourProduct = {
  heading: "Kid Bracelet",
  search: "fa-regular fa-magnifying-glass",
  subData: [
    {
      _id: "1",
      tag: "Bracelet",
      icon: "fa-regular fa-heart",
      icon2: "fa-solid fa-magnifying-glass",
      icon3: "fa-regular fa-chevron-right",
      productImage: Product1,
      productName: "Golden Elegance Cuff",
      productPrice: "£20.00",
      altProductPrice: "£24.00",
    },
    {
      _id: "2",
      tag: "Bracelet",
      icon: "fa-regular fa-heart",
      icon2: "fa-regular fa-magnifying-glass",
      icon3: "fa-regular fa-angle-right",
      productImage: Product2,
      productName: "Golden Elegance Cuff",
      productPrice: "£20.00",
      altProductPrice: "£24.00",
    },
    {
      _id: "3",
      tag: "Bracelet",
      icon: "fa-regular fa-heart",
      icon2: "fa-regular fa-magnifying-glass",
      icon3: "fa-regular fa-chevron-right",
      productImage: Product3,
      productName: "Golden Elegance Cuff",
      productPrice: "£20.00",
      altProductPrice: "£24.00",
    },
    {
      _id: "4",
      tag: "Bracelet",
      icon: "fa-regular fa-heart",
      icon2: "fa-regular fa-magnifying-glass",
      icon3: "fa-regular fa-chevron-right",
      productImage: Product4,
      productName: "Golden Elegance Cuff",
      productPrice: "£20.00",
      altProductPrice: "£24.00",
    },
    {
      _id: "5",
      tag: "Bracelet",
      icon: "fa-regular fa-heart",
      icon2: "fa-regular fa-magnifying-glass",
      icon3: "fa-regular fa-angle-right",
      productImage: Product5,
      productName: "Golden Elegance Cuff",
      productPrice: "£20.00",
      altProductPrice: "£24.00",
    },
    {
      _id: "6",
      tag: "Bracelet",
      icon: "fa-regular fa-heart",
      icon2: "fa-regular fa-magnifying-glass",
      icon3: "fa-regular fa-chevron-right",
      productImage: Product6,
      productName: "Golden Elegance Cuff",
      productPrice: "£20.00",
      altProductPrice: "£24.00",
    },
    {
      _id: "7",
      tag: "Bracelet",
      icon: "fa-regular fa-heart",
      icon2: "fa-regular fa-magnifying-glass",
      icon3: "fa-regular fa-chevron-right",
      productImage: Product7,
      productName: "Golden Elegance Cuff",
      productPrice: "£20.00",
      altProductPrice: "£24.00",
    },
    {
      _id: "8",
      tag: "Bracelet",
      icon: "fa-regular fa-heart",
      icon2: "fa-regular fa-magnifying-glass",
      icon3: "fa-regular fa-chevron-right",
      productImage: Product8,
      productName: "Golden Elegance Cuff",
      productPrice: "£20.00",
      altProductPrice: "£24.00",
    },
    {
      _id: "9",
      tag: "Bracelet",
      icon: "fa-regular fa-heart",
      icon2: "fa-regular fa-magnifying-glass",
      icon3: "fa-regular fa-angle-right",
      productImage: Product9,
      productName: "Golden Elegance Cuff",
      productPrice: "£20.00",
      altProductPrice: "£24.00",
    },
    {
      _id: "10",
      tag: "Bracelet",
      icon: "fa-regular fa-heart",
      icon2: "fa-regular fa-magnifying-glass",
      icon3: "fa-regular fa-chevron-right",
      productImage: Product10,
      productName: "Golden Elegance Cuff",
      productPrice: "£20.00",
      altProductPrice: "£24.00",
    },
    {
      _id: "11",
      tag: "Bracelet",
      icon: "fa-regular fa-heart",
      icon2: "fa-regular fa-magnifying-glass",
      icon3: "fa-regular fa-chevron-right",
      productImage: Product11,
      productName: "Golden Elegance Cuff",
      productPrice: "£20.00",
      altProductPrice: "£24.00",
    },
    {
      _id: "12",
      tag: "Bracelet",
      icon: "fa-regular fa-heart",
      icon2: "fa-regular fa-magnifying-glass",
      icon3: "fa-regular fa-chevron-right",
      productImage: Product12,
      productName: "Golden Elegance Cuff",
      productPrice: "£20.00",
      altProductPrice: "£24.00",
    },

  ]
}
const font = {
  fontFamily: "'Paytone One', sans-serif",
}


const CardStore = () => {
  const [formData] = useState({});
  const people = [
    { id: 1, name: 'Children Bracelets' },
    { id: 2, name: 'Arlene Mccoy' },
    { id: 3, name: 'Devon Webb' },
    { id: 4, name: 'Tom Cook' },
    { id: 5, name: 'Tanya Fox' },
    { id: 6, name: 'Hellen Schmidt' },
  ];
  const [selectedPerson, setSelectedPerson] = useState(people[1]);

  return (
    <>
      <div className="relative pb-32 pt-8">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-6xl 3xl:max-w-8xl mx-auto flex space-x-10">
          <div className="relative flex flex-col space-y-10 w-full">
            <div className="relative flex items-center space-x-3">
              <div className="text-2xl  xl:w-5/6 xl:2xl:text-3xl text-carnation-950" style={font}>{ourProduct.heading}</div>
              <div className="relative w-full xl:max-w-2/12 flex items-center gap-6">
                <div className="relative w-1/2 ">
                  <div className="relative w-full">
                    <InputGroup
                      inputName={"name"}
                      inputPlaceholder={"Search"}
                      inputClasses={"!bg-prontopsy-dodgerBlue !w-full !border !border-slate-200 !bg-white !rounded-full !h-12 placeholder:!text-slate-500 text-carnation-950 bg-white !pl-6 shadow-md"}
                      inputValue={formData.email}
                    />
                    <div className="absolute  top-1/2 right-6 -translate-y-1/2 text-slate-700">
                      <i className={("fa-brands fa-fw", ourProduct.search)}></i>
                    </div>
                  </div>
                </div>
                <div className="relative w-1/2 z-10 flex items-center">
                  <div className="w-full flex items-center gap-3">
                    <div className="relative text-carnation-700 shrink-0">
                      Sort by :
                    </div>
                    <div className="relative w-full shrink">
                      <Listbox value={selectedPerson} onChange={setSelectedPerson}>
                        <Listbox.Button className="relative w-full cursor-default rounded-full border border-slate-200 bg-white  text-left shadow-md flex justify-between items-center px-6 py-3">
                          {selectedPerson.name}
                          <i class="fa-regular fa-angle-down"></i>
                        </Listbox.Button>
                        <Listbox.Options className="absolute mt-1 w-full rounded-md bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                          {people.map((person) => (
                            <Listbox.Option
                              key={person.id}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'
                                }`
                              }
                              value={person}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                      }`}
                                  >
                                    {person.name}
                                  </span>
                                  {selected && (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-carnation-950">
                                      <i class="fa-regular fa-check"></i>
                                    </span>
                                  )}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Listbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative ">
              <SignleProduct data={ourProduct} />
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default CardStore;