import React from 'react'
import MainBanner from "../components/sections/MainBanner"
import bannerImage from "../assets/images/banner.png"
import WhoWeAre1 from "../assets/images/whoWeAre1.jpg"
import WhoWeAre2 from "../assets/images/whoWeAre2.jpg"
import WhoWeAre3 from "../assets/images/whoWeAre3.jpg"
import WhoWeAre from "../components/sections/WhoWeAre"
import WhyPurchaseImage from "../assets/images/whyPurchase.jpg"
import TinyTracker from "../components/sections/TinyTracker"
import OurStore from "../components/sections/OurStore" 
import WhyPurchase from "../components/sections/WhyPurchase"
import OurPrice from "../components/sections/OurPrice"
import Testimonials from "../components/sections/Testimonials"

const bannerDetails = {
  banner:bannerImage,
  bannerTiitle:"Scan Bracelet",
  bannerSubTiitle:"QR for Destination",
  description:"Scan the QR code on your bracelet to access personalized information and navigate effortlessly.",
};

const whoWeAreDetails = {
  subHeading:"Who we are",
  heading:"Our Mission and Vision",
  subdata:[
    {
      _id: 1,
      image: WhoWeAre1,
      title:"Person Recognition",
      description:"Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC.",
      btn:"Read more"
    },
    {
      _id: 2,
      image: WhoWeAre2,
      title:"Find Wandering",
      description:"Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classica.",
      btn:"Read more"
    },
    {
      _id: 3,
      image: WhoWeAre3,
      title:"Find Lost Children",
      description:"Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots.",
      btn:"Read more"
    },
  ]
};

const whyPurchaseData = {
  subHeading:"Trustworthy",
  heading:"Why Purchase MyTinyTracker",
  description:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the  Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetitio.",
  image:WhyPurchaseImage,
}

const Home = () => {
  return (
    <>
      <MainBanner data={bannerDetails}/>
      <WhoWeAre data={whoWeAreDetails}/>
      <TinyTracker/>
      <OurStore/>
      <WhyPurchase data={whyPurchaseData}/>
      <OurPrice/>
      <Testimonials/>
    </>
  )
}

export default Home