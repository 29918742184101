import React from 'react'
import SingleTestimonial from "../items/SingleTestimonial";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
// import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const ourTestimonial = {
  subHeading: "testimonial",
  heading: "What Our Customers Say",
  subData: [
    {
      _id: "1",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",
      name: "Emily Thompson",
    },
    {
      _id: "2",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",
      name: "Emily Thompson",
    },
    {
      _id: "3",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",
      name: "Emily Thompson",
    },
    {
      _id: "4",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",
      name: "Emily Thompson",
    },
    {
      _id: "5",
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's ",
      name: "Emily Thompson",
    },

  ]
}

const Testimonials = () => {
  const font = {
    fontFamily: "'Paytone One', sans-serif",
  }

  const swiperParams = {
    // modules: [Autoplay, Pagination, Navigation],
    pagination: {
      clickable: true,
      dynamicBullets: true,
    }, 
    breakpoints: {
      0: {
        slidesPerView: 1.2,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1280: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
      1900: {
        slidesPerView: 3,
        spaceBetween: 60,
      },
    },
  };

  return (
    <>
      <div className="relative  py-32">
        <div className="relative space-y-5 text-center mx-auto mb-8 2xl:space-y-5">
          <div className="px-8 py-2 text-center text-carnation-950 uppercase text-sm 2xl:text-base font-bold shadow-lg rounded-full inline-block">{ourTestimonial.subHeading}</div>
          <div className="text-4xl 2xl:text-5xl text-carnation-950" style={font}>{ourTestimonial.heading}</div>
        </div>
        <div className="relative w-full px-4  sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-6xl 3xl:max-w-8xl mx-auto flex items-center">
          <Swiper {...swiperParams} className="relative mySwiper">
            {ourTestimonial.subData.map((item, index) => (
              <SwiperSlide key={index}> 
                <SingleTestimonial data={item} />
              </SwiperSlide>
            ))} 
          </Swiper> 
        </div>
      </div>
    </>
  )
}

export default Testimonials;