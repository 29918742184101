import React from 'react'
import Image from "../elements/Image"

const OurStoreBanner = ({ data, subMenu }) => {
  const font = {
    fontFamily:"'Paytone One', sans-serif",
  }
  return (
    <>
      <div className="relative h-[480px] w-full flex flex-col"> 
        <div className="absolute left-0 top-0">
          <div className="relative max-h-[480px] overflow-hidden h-full">
            <div className="w-full h-full">
              <Image
                src={data.banner}
                width={"100%"}
                height={"100%"}
                classes="object-cover object-bottom"
              />
            </div>
            <div className="absolute left-0 top-0 w-full h-full bg-gradient-to-b from-[rgba(255,255,255,0.8)] to-white"></div>
          </div>
        </div>

        <div className="flex w-full flex-col justify-center xl:mt-auto md:mt-14 mt-10 mx-auto my-auto space-y-3">
          <div className="text-4xl 2xl:text-6xl text-carnation-950 z-10 text-center" style={font}>{data.title}</div>
          <ol className="relative mx-auto">
            {subMenu.map((item, index) => (
              <li key={index} className="inline-flex items-center  uppercase">
                {index !== subMenu.length - 1 ? (
                  <>
                    <div className='flex items-center'>
                      <a href={item.href} className="inline-flex items-center text-sm font-medium text-black leading-none">
                        {item.label}
                      </a>
                      <div className='"w-4 h-4 text-gray-600 mx-2 leading-none'>
                        <i className="fa-solid fa-angles-right"></i>
                      </div>
                    </div> 
                  </>
                ) : (
                  <span className="text-sm font-medium text-black">{item.label}</span>
                )}
              </li>
            ))}
          </ol>
        </div>
      </div>
    </>
  )
}

export default OurStoreBanner